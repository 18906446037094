'use client'
import config from "@/tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
import { useState, useEffect } from "react";

const tailwindScreens = resolveConfig(config).theme?.screens as Record<string, string>


const useScreens = () => {
  const [matches, setMatches] = useState<Record<string, boolean>>();

  useEffect(() => {

    function calculateScreenMatches() {
      let setState = false
      const screenKeys = Object.keys(tailwindScreens)
      const mediaQueries:Record<string, MediaQueryList> = {}
      const screenMatches:Record<string, boolean> = {}
      
      for (let i = 0;  i < screenKeys.length; i++) {
        mediaQueries[screenKeys[i]] = window.matchMedia(`(min-width: ${tailwindScreens[screenKeys[i]]})`)
        screenMatches[screenKeys[i]]= mediaQueries[screenKeys[i]].matches
        if (matches === undefined || matches[screenKeys[i]] === undefined || matches[screenKeys[i]] !== screenMatches[screenKeys[i]]) {
          setState = true
        }
      }
  
      if (setState) {
        setMatches(screenMatches)
      }
    }

    calculateScreenMatches()
    
    const listener = () => calculateScreenMatches();
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches]);

  return matches;
}

export default useScreens;