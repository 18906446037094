import { HideOnIFrameCompact } from "@/contexts/IFrameCompactProvider"
import { Disclosure } from "@headlessui/react"
import { Bars3Icon, MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/20/solid"
import Link from "next/link"
import LogoSVG from '@/public/assets/logo.svg';
import { DrupalMenuLinkContent } from "next-drupal"
import { Session } from "next-auth"
import { DropDown } from "./Header.client";
import classNames from "classnames";
import { UserNav } from "./UserNav.client";
import { useState } from "react";
import { useSearchUi } from "@/contexts/SearchUiProvider";
import { useProducts } from "@/contexts/ProductsProvider";
import { usePathname } from "next/navigation";

export const MobileHeader = ({ menu, session }: {
  menu: DrupalMenuLinkContent[],
  session: Session | null
}) => {
  const [open, setOpen] = useState(false);
  const { isVisible, showSearchUi, hideSearchUi, current, runSearch } = useSearchUi()
  const { products } = useProducts()
  const pathname = usePathname()
  
  const toggleSearch = () => {
    if (!isVisible && !current?.searchPhrase && (!current?.products || current?.products.length == 0)) {
      const inContextProducts = products.filter(p => pathname.startsWith(p.path.alias))
      if (inContextProducts.length) {
        return runSearch(null, [inContextProducts.reduce((a, b) => a.path.alias.length > b.path.alias.length ? a : b).name])
      }
      return showSearchUi()
    }
    else {
      return isVisible ? hideSearchUi() : showSearchUi()
    }
  }
  return <>
    <Disclosure as="nav" className="p-3 text-navy-600">
      <>
        <div className="mx-auto container px-2">
          <div className="relative flex h-16 items-center justify-between">
            <div className="flex flex-1 items-stretch justify-start">
              <div className="flex flex-shrink-0 items-center">
                <Link className="flex place-items-baseline" href="/">
                  <LogoSVG className='me-1 h-6' />
                  <span className='uppercase text-[30px] font-thin'>Docs</span>
                </Link>
              </div>
            </div>
            <HideOnIFrameCompact>
              <div className='flex flex-row-reverse basis-1/3 gap-x-4'>
                <div className='max-h-[50px]'><UserNav session={session} /></div>
                <a className='hidden md:block border w-fit whitespace-nowrap border-navy-600 rounded p-3 hover:bg-navy-600 hover:text-white transition' href="https://www.acquia.com/" target="_blank">Acquia.com</a>
                <button className={classNames('border w-fit whitespace-nowrap border-white rounded-full p-3 hover:bg-navy-600 hover:text-white transition-all duration-300', isVisible ? 'text-white bg-navy-600' : '')} onClick={toggleSearch}><MagnifyingGlassIcon className="w-[24px]" /></button>
              </div>
              <div className="inset-y-0 right-0 flex items-center print:hidden ml-4">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2  hover:bg-navy-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" onClick={() => setOpen(!open)}>
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true"  />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </HideOnIFrameCompact>
          </div>
          <Disclosure.Panel>
          <div className="space-y-1 px-2 pb-3 pt-2 divide-y divide-solid divide-navy-300">
            <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start p-2 md:hidden'>
              <a className='border w-fit whitespace-nowrap border-navy-600 rounded p-3 hover:bg-navy-600 hover:text-white transition' href="https://www.acquia.com/" target="_blank">Acquia.com</a>
            </div>
            {menu && menu.map((link, i) => {
              return link.items ?
                (
                  <div className='block' key={`header-mobile-menu-item-${i}`}>
                    <DropDown buttonLink={link.url} route={link.route} buttonLabel={link.title} key={`header-mobile-menu-item-${i}`} items={link.items ?? []} />
                  </div>
                )
                : (
                  <Disclosure.Button
                    key={link.title}
                    as="a"
                    href={link.url}
                    className="block rounded-md px-3 py-2 text-base font-medium"
                  >
                    {link.title}
                  </Disclosure.Button>
                )
            })}
          </div>
        </Disclosure.Panel>
        </div>
      </>
    </Disclosure>
  </>
}